import type { PersonName } from "~/components/AboutUs/Team/fluxonTeamList.ts";

type QuoteType = {
  text: string;
  author: string;
  role: string;
  imageName: PersonName;
  position: {
    left: number;
    top: number;
  };
  positionTablet: {
    left: number;
    top: number;
  };
};

export const quotes: QuoteType[] = [
  {
    text: "“We never miss a deadline. We have an incredibly high bar — and I enjoy meeting the challenge, every time.”",
    author: "Rishi Srivatsavai",
    role: "Director of Engineering · India",
    imageName: "rishi",
    position: {
      left: 903,
      top: 236,
    },
    positionTablet: {
      left: 454,
      top: 27,
    },
  },
  {
    text: "“Fluxon prioritizes client satisfaction over profit maximization, unlike other companies that increase project budgets without justification.”",
    author: "Mariya Taran",
    role: "TPM Director · Portugal",
    imageName: "mariya",
    position: {
      left: 861,
      top: 193,
    },
    positionTablet: {
      left: 339,
      top: -30,
    },
  },
  {
    text: "“We combine the best of startups with our expertise and scale from places like Google where I came from. It's a magical combination!”",
    author: "Karl Bohan",
    role: "Director of Product · USA",
    imageName: "karl",
    position: {
      left: 389,
      top: 235,
    },
    positionTablet: {
      left: 64,
      top: 16,
    },
  },
  {
    text: "“Throughout my career, and especially at Fluxon, my favorite projects have always been some form of that: finding the possibilities beyond what people say you can do.”",
    author: "Subir Chatterjee",
    role: "Director of Operations · India",
    imageName: "subir",
    position: {
      left: 974,
      top: 196,
    },
    positionTablet: {
      left: -23,
      top: -27,
    },
  },
  {
    text: "“Working at Fluxon keeps you on your toes, keeps you alert. And there’s a feeling that we can get things done.”",
    author: "Edgard Lindner",
    role: "Director of Engineering · USA",
    imageName: "edgard",
    position: {
      left: 241,
      top: 190,
    },
    positionTablet: {
      left: -23,
      top: -27,
    },
  },
  {
    text: "“At Fluxon, every product is crafted for impact, driven by collaboration and meaningful connections.”",
    author: "Jakub Skonieczny",
    role: "Design Lead · Poland",
    imageName: "jakub",
    position: {
      left: 724,
      top: 122,
    },
    positionTablet: {
      left: 28,
      top: -24,
    },
  },
  {
    text: "“The tangible impact and positive feedback that we see from our clients always brings a smile to my face.”",
    author: "Kenny Leung",
    role: "Lead Product Manager · Canada",
    imageName: "kenny",
    position: {
      left: 280,
      top: 56,
    },
    positionTablet: {
      left: 28,
      top: -24,
    },
  },
];

export const imageNames: PersonName[] = [
  "edgard",
  "queenie",
  "mariya",
  "haiku",
  "tong",
  "rishi",
  "jakub",
  "karl",
  "dmitri",
  "kevin",
  "daniel",
  "praveen",
  "krishna",
  "marka",
  "vlad",
  "vikash",
  "babneet",
  "rahul",
  "anton",
  "kenny",
  "santosh",
  "vadim",
  "vamsi",
  "michal",
  "subir",
  "alix",
  "gialdo",
  "kevinChie",
  "maryna",
  "ena",
  "jen",
  "aj",
];
export const desktopImagePositions: [number, number][] = [
  [119, 177], // edgard
  [169, 193], // queenie
  [481, 185], //mariya
  [166, 120], //haiku
  [206, 95], //tong
  [505, 203], //rishi
  [403, 137.5], // jakub
  [205, 215], //karl
  [423.5, 160], // dmitri
  [189, 113], //kevin
  [109, 140], //daniel
  [559, 240], //praveen
  [570, 220], //krishna
  [439, 197], //marka
  [410, 190], //vlad
  [549, 220], //vikash
  [605, 203], //babneet
  [579, 210], //rahul
  [445, 140], //anton
  [139, 105], //kenny
  [569, 193], //santosh
  [206, 75], //vadim
  [549, 220], //vamsi
  [419, 113], //michal
  [550, 200], //subir
  [139, 75], //alix
  [383, 183.5], //gialdo
  [149, 140], //kevin
  [409, 105], //maryna
  [165, 170], //ena
  [183, 90], //jen
  [130, 148], //aj
];
