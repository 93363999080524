import React from "react";

type ImageCircleProps = {
  animated: boolean;
  index: number;
  cx: number;
  cy: number;
  r: string;
  fill: string;
};
const ImageCircle: React.FC<ImageCircleProps> = ({ animated, index, cx, cy, r, fill }) => {
  return (
    <circle
      className={`image-circle ${animated && "animated"}`}
      cx={cx}
      cy={cy}
      r={r}
      fill={fill}
      style={{
        animation: animated
          ? "pulse 2s 12"
          : index % 2 === 0
            ? `personFadeIn ${index}s infinite`
            : `personFadeOut ${index}s infinite`,
      }}
      strokeWidth="5"
      paintOrder="stroke"
    >
      <animate attributeName="r" begin="0s" dur="1s" repeatCount="1" from="8" to="16" />
    </circle>
  );
};

export default ImageCircle;
