import { useState } from "react";
import SwiperCore from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, type SwiperClass } from "swiper/react";
import { personImages } from "~/components/AboutUs/Team/fluxonTeamList";
import { quotes } from "~/components/home/Map/mapDetails.ts";
import { disableMotion } from "~/config";

SwiperCore.use([Pagination, Autoplay]);

export const PeopleSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper: SwiperClass) => {
    const realIndex = swiper.realIndex;
    setActiveIndex(realIndex);

    /*
     * Add "completed" class to all bullets before the active one.
     * This is done to show the progress of the slider instead of animating the active bullet only.
     */
    const bullets = document.querySelectorAll(".swiper-pagination-bullet");
    bullets.forEach((bullet, index) => {
      if (index < realIndex) {
        bullet.classList.add("completed");
      } else {
        bullet.classList.remove("completed");
      }
    });
  };

  const slides = quotes
    .filter((quote) => quote !== null)
    .map((quote, index) => {
      return (
        <SwiperSlide key={index} style={{ height: "auto", display: "flex" }}>
          <div className="relative flex flex-col p-8 px-20 max-md:px-6 ">
            <div className="heading-4 h-[10rem] pb-8 max-md:h-auto">
              <div className="mb-[1rem] block size-[4.25em] overflow-hidden rounded-[50%]">
                <img
                  className="w-full"
                  src={personImages[quote.imageName].src}
                  alt={quote.author}
                />
              </div>
              <p className="heading-4 pb-1">{quote.author}</p>
              <p className="text-overline text-dark-80">{quote.role}</p>
              <p className="heading-6 mt-5">{quote.text}</p>
            </div>
          </div>
        </SwiperSlide>
      );
    });

  const swiperSettings = {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 24,
    speed: 1000,
    autoplay: disableMotion
      ? false
      : {
          delay: 5000,
          disableOnInteraction: false,
        },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index: number) {
        return `<span class="swiper-pagination-bullet inline-flex h-1 flex-1 bg-white  ${
          index === activeIndex ? "bg-opacity-100" : ""
        }"></span>`;
      },
    },
    onSlideChange: handleSlideChange,
  };

  return (
    <Swiper
      {...swiperSettings}
      style={{ alignItems: "stretch", width: "100%" }}
      className="mx-5 min-h-[400px] min-w-full rounded-[17.5px] bg-[linear-gradient(263deg,#292636_6.51%,#272f7b_52.47%,#2537cc_94.81%)]"
    >
      {slides}
      <div className="swiper-pagination absolute bottom-0 left-0 z-10 flex w-full gap-4 px-20 pb-8 max-md:gap-2 max-md:px-7 max-md:pb-5"></div>
    </Swiper>
  );
};
